import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";

function OurVideos() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <BaseLayout PageName="Our Videos">
            <div className="w-full">
                <TransparentHeader
                    headertitle="Our Videos"
                    Subheader="Our Videos"
                    bgimg={`${rpdata?.gallery?.[12]}`}
                />
                <div className="py-[100px] text-center w-4/5 mx-auto">
                    <h2> Our Videos </h2>
                    <div className="py-10">
                        <div class="elfsight-app-a540086a-d1d2-43f4-a69f-28557a1f22be"></div>
                    </div>
                </div>
            </div>
        </BaseLayout>
    );
}

export default OurVideos;