import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";

function Reviews() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <BaseLayout PageName="Reviews">
            <div className="w-full">
                <TransparentHeader
                    headertitle="Reviews"
                    Subheader="Reviews"
                    bgimg={`${rpdata?.gallery?.[12]}`}
                />
                <div className="py-[100px] text-center w-4/5 mx-auto">
                    <h2> Our Reviews </h2>
                    <div className="py-10">
                        <div class="elfsight-app-008f7989-bb85-48f4-98fc-c417cc8f5d03"></div>
                    </div>
                </div>
            </div>
        </BaseLayout>
    );
}

export default Reviews;